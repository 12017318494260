import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import CustomTooltip from "../../../../src/utils/CustomTooltip";
import { Download } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsyncDropdown from "../../../utils/asyncDropdown/AsyncDropdown";
import { getActivepanels } from "../../managePanel/managePanel.api";
import {
  getAllLanguage,
  getActiveCountry,
  getSurveyParticipationPointsDetail,
  getSurveyParticipationPoints,
  getPanelistPointDetail,
  getPanelistPointSummary,
  getPanelistPointRedeemedDetail,
  getPanelistPointRedeemed,
  getPanelistDetails,
} from "../panelistDetail.api";
import CustomDataTable from "../../../views/CustomTable/CustomDataTable";
import { LoadingButton } from "@mui/lab";
import ConfirmAlert from "../../../utils/ConfirmAlert";
import { SnackBarAlert } from "../../../utils/SnackBar";
import "../../panelistDetail/PanelList.css";
import { updatePanelist, getPanelistById } from "../panelistDetail.api";
import { useParams } from "react-router-dom";
import validationPatterns from "../../../utils/ZipValidationPatterns.json";
import { copyToClipboard } from "../../../utils/copyToClipboard/copyToClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const dropDowns = {
  LANGUAGE: "LANGUAGE",
  COUNTRY: "COUNTRY",
  USERTYPE: "USERTYPE",
};
const PanelistForm = (props) => {
  const { editData } = props;
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const data = {
    panelGuid: "75a22a9e-d0e4-4547-af6b-6156bb0760eb",
    panelId: 30,
  };
  const [activePanels, setActivePanels] = useState([]);
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [columns, setColumns] = useState([]);
  const [surveyColumns, setSurveyColumns] = useState([]);
  const [redeemColumns, setRedeemColumns] = useState([]);
  const [pending, setPending] = useState(true);
  const [reconcileRate, setReconcileRate] = useState(0.0);
  const [reconcile, setReconcile] = useState(0);
  const [conversion, setConversion] = useState(0.0);
  const [revenue, setRevenue] = useState(0);
  const [totalcost, setTotalcost] = useState(0.0);
  const [panelistData, setPanelistData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dob: moment().format("YYYY-MM-DD"),
    age: 0,
    email: "",
    zipCode: "",
    mobile: "",
    countryId: 0,
    languageId: 0,
    currency: "",
    address: "",
    state: "",
    city: "",
    joinIp: "",
    joinDate: moment().format("YYYY-MM-DD"),
    createdAt: moment().format("YYYY-MM-DD"),
    updatedAt: moment().format("YYYY-MM-DD"),
    isEmailVerified: false,
    emailVerifyDate: moment().format("YYYY-MM-DD"),
    device: "",
    sourceName: "",
    unsubscribe: false,
    unsubscribeDate: moment().format("YYYY-MM-DD"),
    isSampleAllow: false,
    isActive: false,
    usertype: 0,
    levelId: 0,
    reconcile: 0,
    rrate: 0.0,
    conversion: 0.0,
    revenue: 0,
    totalcost: 0.0,
    emailScore: 0,
    visitorID: "",
    prevEmail: "",
    deleteReason: "",
  });

  const [panelistId, setPanelistId] = useState("");
  const [pointsDetail, setPointsDetail] = useState([]);
  const [Points, setPoints] = useState({
    totalPoints: 0,
    totalSurveyPoint: 0,
    totalRefferalPoint: 0,
    totalMiniSurveyPoint: 0,
    totalTerminateCost: 0.0,
    totalReconcilePoints: 0.0,
    totalTerminatePoint: 0,
  });
  const [SurveyPointsDetail, setSurveyPointsDetail] = useState([]);
  const [SurveyPoints, setSurveyPoints] = useState({
    totalAttempts: 0,
    completes: 0,
    terminates: 0,
    quotaFull: 0,
    inCompletes: 0,
    reconcile: 0,
  });
  const [RedemptionPointsDetail, setRedemptionPointsDetail] = useState([]);
  const [RedemptionPoints, setRedemptionPoints] = useState({
    totalPoints: 0,
    pointredeemed: 0,
    balancepoints: 0,
    approvedPoints: 0,
    rejectedPoints: 0,
    pendingPoints: 0,
  });
  const [validationError, setvalidationError] = useState({
    email: "",
    zipCode: "",
  });
  const [alert, setAlert] = useState(false);
  const [isDisableUpdate, setIsDisableUpdate] = useState(false);
  const { id } = useParams();
  const [zipMaxLength, setZipMaxLength] = useState(30);
  const [userTypes, setUserTypes] = useState([
    { id: 0, userType: "0" },
    { id: 1, userType: 1 },
    { id: 2, userType: 2 },
    { id: 3, userType: 3 },
    { id: 4, userType: 4 },
  ]);
  const [selectedUserType, setSelectedUserType] = useState(0);
  const [w9FormColumns, setW9FormColumns] = useState([]);
  const [W9FormDetail, setW9FormDetail] = useState([]);
  const w9FormRemoveObj = ["fw9Url"];
  const [redemptionPointDivider, setRedemptionPointDivider] = useState(100);
  const [profit, setProfit] = useState("0");
  useEffect(() => {
    if (adminId) {
      getActivepanels(adminId)
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            setActivePanels(data);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [adminId]);

  useEffect(() => {
    if (adminId && editData?.panelistId) {
      let zipPatternObjects = validationPatterns.filter((e) =>
        e.countryids.find((l) => l == editData.countryId)
      );
      setZipMaxLength(zipPatternObjects[0].maxlength);

      getAllLanguage()
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            // console.log("... language data", data);

            setLanguages(data);
          }
        })
        .catch((err) => console.log(err));

      getActiveCountry()
        .then((res) => {
          if (res?.status === 200) {
            const { data } = res.data;
            // console.log("... country data", data);
            setCountries(data);
            //setSelectedCountry(data[0]);
          }
        })
        .catch((err) => console.log(err));

      getPanelistPointSummary(editData?.panelistId)
        .then((res) => {
          if (res?.status === 200) {
            const { data, success } = res.data;
            if (success) {
              // console.log("... points data", data);
              data[0].totalTerminateCost =
                data[0].totalTerminateCost != null
                  ? parseFloat(data[0].totalTerminateCost).toFixed(2)
                  : 0.0;
              data[0].totalReconcilePoints =
                data[0].totalReconcilePoints != null
                  ? parseFloat(data[0].totalReconcilePoints).toFixed(2)
                  : 0.0;

              setPoints(data[0]);
            } else {
              setPoints({
                totalPoints: 0,
                totalSurveyPoint: 0,
                totalRefferalPoint: 0,
                totalMiniSurveyPoint: 0,
                totalTerminateCost: 0.0,
                totalReconcilePoints: 0.0,
                totalTerminatePoint: 0,
              });
            }
          }
        })
        .catch((err) => console.log(err));

      getPanelistPointDetail(editData?.panelistId)
        .then((res) => {
          if (res?.status === 200) {
            const { data, success } = res.data;
            if (success) {
              // console.log("... points details data", data);
              setPointsDetail(data);
            }
          }
        })
        .catch((err) => console.log(err));

      getSurveyParticipationPoints(editData?.panelistId)
        .then((res) => {
          if (res?.status === 200) {
            const { data, success } = res.data;
            if (success) {
              // console.log("... Surveypoints data", data);
              setSurveyPoints(data[0]);
              setReconcileRate(data[0].rrate);
              setReconcile(data[0].reconcile);
              setConversion(data[0].conversion);
              setRevenue(data[0].revenue);
              setTotalcost(
                data[0].totalcost != null
                  ? parseFloat(data[0].totalcost).toFixed(2)
                  : 0.0
              );

              setProfit(
                data[0].profit != null
                  ? parseFloat(data[0].profit).toFixed(2).toString()
                  : "0"
              );
            } else {
              // console.log("... Surveypoints data", data);
              setSurveyPoints({
                totalAttempts: 0,
                completes: 0,
                terminates: 0,
                quotaFull: 0,
                inCompletes: 0,
                reconcile: 0,
              });

              setReconcileRate(0.0);
              setReconcile(0);
              setConversion(0.0);
              setRevenue(0);
              setTotalcost(0.0);
            }
          }
        })
        .catch((err) => console.log(err));

      getSurveyParticipationPointsDetail(editData?.panelistId)
        .then((res) => {
          if (res?.status === 200) {
            const { data, success } = res.data;
            if (success) {
              // console.log("... points details data", data);
              setSurveyPointsDetail(data);
            }
          }
        })
        .catch((err) => console.log(err));

      getPanelistPointRedeemed(editData?.panelistId)
        .then((res) => {
          if (res?.status === 200) {
            const { data, success } = res.data;
            if (success) {
              // console.log("... Redeem data", data);
              setRedemptionPoints(data[0]);
            } else {
              setRedemptionPoints({
                totalPoints: 0,
                pointredeemed: 0,
                balancepoints: 0,
                approvedPoints: 0,
                rejectedPoints: 0,
                pendingPoints: 0,
              });
            }
          }
        })
        .catch((err) => console.log(err));

      getPanelistPointRedeemedDetail(editData?.panelistId)
        .then((res) => {
          if (res?.status === 200) {
            const { data, success } = res.data;
            if (success) {
              // console.log("... Redeem points details data", data);
              setRedemptionPointsDetail(data);
            }
          }
        })
        .catch((err) => console.log(err));
      getPanelistDetails(editData?.panelistId)
        .then((res) => {
          if (res?.status === 200) {
            const { data, success } = res.data;
            if (success) {
              // console.log("...W9Form details data", data);
              setW9FormDetail(res.data.data);
              // panellistW9FormDetailsDataColumn();  static
              panellistW9FormDetailsDataColumn(res.data.data); //Dynamic
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [adminId, editData]);

  useEffect(() => {
    if (editData?.panelistId) {
      // console.log("edit data", editData);
      setPanelistId(editData.panelistId);
      let duplicate = { ...panelistData };
      duplicate.firstName = editData?.firstName;
      duplicate.lastName = editData?.lastName;
      duplicate.gender = editData?.gender;
      duplicate.dob = moment(editData?.dob).format("YYYY-MM-DD");
      duplicate.age = editData?.age;
      duplicate.email = editData?.email;
      duplicate.zipCode = editData?.zipCode;
      duplicate.createdAt = editData?.createdAt;
      duplicate.updatedAt = editData?.updatedAt;
      duplicate.languageId = editData?.languageId;
      duplicate.countryId = editData?.countryId;
      duplicate.currency = editData?.currency;
      duplicate.address = editData?.address;
      duplicate.state = editData?.state;
      duplicate.city = editData?.city;
      duplicate.mobile = editData?.mobile;
      duplicate.joinIp = editData?.joinIp;
      duplicate.joinDate = moment(editData?.joinDate).format("YYYY-MM-DD");
      duplicate.isEmailVerified = editData?.isEmailVerified;
      duplicate.emailVerifyDate = editData?.emailVerifyDate
        ? moment(editData?.emailVerifyDate).format("YYYY-MM-DD")
        : "";
      duplicate.device =
        editData.device === 1
          ? "iOS"
          : editData?.device === 2
          ? "Android"
          : "Website";
      duplicate.sourceName = editData?.sourceName;
      duplicate.unsubscribe = editData?.unsubscribe; //add
      duplicate.unsubscribeDate = editData?.unsubscribeDate
        ? moment(editData?.unsubscribeDate).format("YYYY-MM-DD")
        : "";
      duplicate.isSampleAllow = editData?.isSampleAllow;
      duplicate.isActive = editData?.isActive;
      duplicate.usertype = editData?.usertype;
      duplicate.levelId = editData?.levelId;
      duplicate.reconcile = editData?.reconcile;
      duplicate.rrate = editData?.rrate;
      duplicate.conversion = editData?.conversion;
      duplicate.revenue = editData?.revenue;
      duplicate.totalcost = editData?.totalcost?.toFixed(2);
      duplicate.emailScore = editData?.emailScore;
      duplicate.visitorID = editData?.visitorid;
      duplicate.prevEmail = editData?.prevEmail;
      duplicate.deleteReason = editData?.deleteReason;

      // setPanelistData(duplicate);
      if (languages?.length > 0) {
        const item = languages.find((i) => i.id === editData.languageId);
        if (item) {
          setSelectedLanguage(item);
        }
      }

      if (countries?.length > 0) {
        const item = countries.find((i) => i.countryId === editData.countryId);
        if (item) {
          setSelectedCountry(item);
        }
      }

      if (userTypes.length > 0) {
        const item = userTypes.find((i) => i.userType == editData.usertype);
        if (item) {
          setSelectedUserType(item);
        }
      }

      setPanelistData(duplicate);
    }

    return () => {
      setPanelistData({
        firstName: "",
        lastName: "",
        gender: "",
        dob: moment().format("YYYY-MM-DD"),
        age: 0,
        email: "",
        zipCode: "",
        mobile: "",
        countryId: 0,
        languageId: 0,
        currency: "",
        address: "",
        state: "",
        city: "",
        joinIp: "",
        joinDate: moment().format("YYYY-MM-DD"),
        createdAt: moment().format("YYYY-MM-DD"),
        updatedAt: moment().format("YYYY-MM-DD"),
        isEmailVerified: false,
        emailVerifyDate: moment().format("YYYY-MM-DD"),
        device: "",
        sourceName: "",
        unsubscribe: false,
        unsubscribeDate: moment().format("YYYY-MM-DD"),
        isSampleAllow: false,
        isActive: false,
        usertype: 0,
        levelId: 0,
        reconcile: 0,
        rrate: 0.0,
        conversion: 0.0,
        revenue: 0,
        totalcost: 0.0,
        emailScore: 0,
        visitorID: "",
        prevEmail: "",
        deleteReason: "",
      });
    };
  }, [editData, activePanels, languages, countries]);

  const handleDropDownChange = (value, dropDownName) => {
    switch (dropDownName) {
      case dropDowns.LANGUAGE:
        setSelectedLanguage(value);
        handleAssignId("Language", value.id);
        break;
      case dropDowns.COUNTRY:
        setSelectedCountry(value);
        handleAssignId("Country", value.id);
        break;

      case dropDowns.USERTYPE:
        setSelectedUserType(value);
        handleAssignId("UserType", value.id);
        break;
      default:
        break;
    }
  };

  const handleAssignId = (keyName, id) => {
    if (keyName && id) {
      setPanelistData((prev) => ({
        ...prev,
        [keyName]: id,
      }));
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSurveyColumns([
        {
          name: "Participation ID",
          selector: (row) => row.id,
          sortable: true,
          width: "150px",
        },
        {
          name: "Survey ID",
          selector: (row) => row.surveyid,
          sortable: true,
        },
        {
          name: "Survey Code",
          selector: (row) => row.surveycode,
          sortable: true,
          width: "130px",
        },
        {
          name: "Completed Points",
          selector: (row) => row.completedPoints,
          sortable: true,
          width: "159px",
        },
        {
          name: "Client",
          selector: (row) => row.clientName,
          sortable: true,
          width: "100px",
        },
        {
          name: "User Status",
          selector: (row) => row.userStatus,
          sortable: true,
        },
        {
          name: "Reconcile",
          selector: (row) => row.isReconcile,
          sortable: true,
          cell: (row) => (row.isReconcile == true ? <>{"True"}</> : "False"),
        },
        {
          name: "Original Status",
          selector: (row) => row.originalStatus,
          sortable: true,
          width: "150px",
          cell: (row) =>
            row.originalStatus != null ? row.originalStatus : "--",
        },

        {
          name: "Start Date",
          // selector: (row) => row?.startDate ? moment.utc(row?.startDate).format("YYYY-MM-DD HH:mm") : "-",
          selector: (row) =>
            row?.startDate
              ? moment(row?.startDate).format("YYYY-MM-DD HH:mm")
              : "-",

          sortable: true,
          width: "150px",
        },
        {
          name: "End Date",
          // selector: (row) => row?.endDate ? moment.utc(row?.endDate).format("YYYY-MM-DD HH:mm") : "-",
          selector: (row) =>
            row?.endDate
              ? moment(row?.endDate).format("YYYY-MM-DD HH:mm")
              : "-",

          sortable: true,
          width: "150px",
        },
        {
          name: "Time Taken",
          selector: (row) => {
            return renderSurveyTimeCalculation(row?.startDate, row?.endDate);
          },
          sortable: true,
          width: "173px",
        },
      ]);
      setPending(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: "Point ID",
          selector: (row) => row.id,
          sortable: true,
        },
        {
          name: "Survey ID",
          selector: (row) => row.surveyId,
          sortable: true,
        },
        {
          name: "Points",
          selector: (row) => row.points,
          sortable: true,
        },
        {
          name: "Points Type",
          selector: (row) => row.pointType,
          sortable: true,
        },
        {
          name: "User Status",
          selector: (row) => row.UserStatus,
          sortable: true,
        },
        {
          name: "Created ON",
          selector: (row) => moment(row.createdAt).format("YYYY-MM-DD"),
          sortable: true,
        },
      ]);
      setPending(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRedeemColumns([
        {
          name: "ID",
          selector: (row) => row.id,
          sortable: true,
          width: "90px",
        },
        {
          name: "Points",
          selector: (row) => row.pointsRedeemed,
          sortable: true,
          width: "90px",
        },
        {
          name: "USD",
          selector: (row) => row.USDAmount,
          sortable: true,
          width: "80px",
        },
        {
          name: (
            <Tooltip title="Currency" placement="top">
              <div className="conversion-header">Currency</div>
            </Tooltip>
          ),
          selector: (row) => row.currencyAmount,
          sortable: true,
          width: "60px",
        },
        {
          name: "Reward",
          selector: (row) => (
            <Tooltip title={row.rewardName} placement="top">
              <div>{row.rewardName}</div>
            </Tooltip>
          ),
          sortable: true,
          width: "100px",
        },
        {
          name: "Status",
          selector: (row) => (
            <Tooltip title={row.status} placement="top">
              <div>{row.status}</div>
            </Tooltip>
          ),
          sortable: true,
          width: "95px",
        },
        {
          name: (
            <Tooltip title="Visitor Count" placement="top">
              <div className="conversion-header">VCount</div>
            </Tooltip>
          ),
          selector: (row) => (
            <CustomTooltip
              title={panelistData.visitorID}
              childrenText={row.visitorCount}
              copiedText
            />
          ),
          sortable: true,
          width: "100px",
        },
        {
          name: "Redemption",
          name: (
            <Tooltip title="Redemption" placement="top">
              <div>Redemption</div>
            </Tooltip>
          ),
          selector: (row) => row.redemptionStatus,
          sortable: true,
          width: "120px",
        },
        {
          name: "Reason",
          selector: (row) => (
            <CustomTooltip
              title={row.disapprovedReason || "No reason provided"}
              childrenText={row.disapprovedReason || "N/A"}
            />
          ),
          sortable: true,
          width: "100px",
        },
        {
          name: "Created",
          selector: (row) => moment(row.createdAt).format("YY-MM-DD"),
          sortable: true,
          width: "100px",
        },
        {
          name: (
            <Tooltip title="Updated" placement="top">
              <div className="conversion-header">Updated</div>
            </Tooltip>
          ),
          selector: (row) => moment(row.updatedAt).format("YY-MM-DD"),
          sortable: true,
          width: "100px",
        },
      ]);
      setPending(false);
    }, 0);
    return () => clearTimeout(timeout);
  }, [panelistData]);

  // const panellistW9FormDetailsDataColumnOld = () => {
  //   const timeout = setTimeout(() => {
  //     setW9FormColumns([
  //       {
  //         name: 'ID',
  //         selector: (row) => row.id,
  //         sortable: true
  //       },

  //       {
  //         name: 'Panelist Id',
  //         selector: (row) => row.panelistId,
  //         sortable: true,
  //         width: "11%",
  //       },
  //       {
  //         name: 'FirstName',
  //         selector: (row) => row.firstName,
  //         sortable: true,
  //         width: "10%",
  //       },
  //       {
  //         name: 'LastName',
  //         selector: (row) => row.lastName,
  //         sortable: true,
  //         width: "10%",
  //       },
  //       {
  //         name: 'SSN',
  //         selector: (row) => (row.ssn == null ? "--" : row.ssn),
  //         sortable: true
  //       },
  //       {
  //         name: 'Address',
  //         selector: (row) => (row.address == null ? "--" : row.address),
  //         sortable: true
  //       },

  //       {
  //         name: 'City',
  //         selector: (row) => (row.city == null ? "--" : row.city),
  //         sortable: true
  //       },
  //       {
  //         name: 'State',
  //         selector: (row) => (row.state == null ? "--" : row.state),
  //         sortable: true
  //       },
  //       {
  //         name: 'updatedAt',
  //         selector: (row) =>  moment(row.updatedAt).format("YYYY-MM-DD"),
  //         sortable: true,
  //         width: "13%",
  //       },
  //       {
  //         name: '',
  //         sortable: true,
  //         width: "5%",
  //         cell: (row) => (
  //           <>
  //             <div>
  //               <Stack direction={"row"}>
  //                 <Tooltip title="Download">
  //                   <IconButton>
  //                     <Download onClick={() => { { window.open(row.fw9Url)} }} />
  //                   </IconButton>
  //                 </Tooltip>
  //               </Stack>
  //             </div>
  //           </>
  //         ),
  //       }
  //     ])
  //   })
  // }

  const panellistW9FormDetailsDataColumn = (data) => {
    const timeout = setTimeout(() => {
      let myFilterObject1 = [];
      const entries = Object.entries(data[0]);
      for (let [key, row] of entries) {
        let isExits = w9FormRemoveObj.includes(key);
        if (!isExits) {
          if (key != "createdAt" && key != "updatedAt") {
            myFilterObject1.push({
              name: key,
              selector: () => (row == null ? "--" : row),
              sortable: true,
            });
          }
          if (key == "createdAt" || key == "updatedAt") {
            myFilterObject1.push({
              name: key,
              selector: () =>
                row == null ? "--" : moment(row).format("YYYY-MM-DD"),
              sortable: true,
              width: "12%",
            });
          }
        }
      }
      myFilterObject1.push({
        name: "w9Form",
        cell: (row) => (
          <>
            <div>
              <Stack direction={"row"}>
                <Tooltip title="Download">
                  <IconButton>
                    <Download
                      onClick={() => {
                        {
                          window.open(data[0].fw9Url);
                        }
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </div>{" "}
          </>
        ),
      });

      setW9FormColumns(myFilterObject1);
      setPending(false);
    }, 1000);
  };

  const handleToggleCheckBox = (e, value) => {
    if (panelistData?.isEmailVerified == false) {
      panelistData.isEmailVerified = true;
    } else {
      panelistData.isEmailVerified = false;
    }

    const { name } = e.target;
    setPanelistData((prev) => ({
      ...prev,
      [name]: Boolean(value),
    }));
  };

  const handleToggleSubscribeCheckBox = (e, value) => {
    if (panelistData?.unsubscribe == false) {
      panelistData.unsubscribe = true;
    } else {
      panelistData.unsubscribe = false;
    }
    const { name } = e.target;
    setPanelistData((prev) => ({
      ...prev,
      [name]: Boolean(value),
    }));
  };

  const handleToggleSampleAllowCheckBox = (e, value) => {
    if (panelistData?.isSampleAllow == false) {
      panelistData.isSampleAllow = true;
    } else {
      panelistData.isSampleAllow = false;
    }
    const { name } = e.target;
    setPanelistData((prev) => ({
      ...prev,
      [name]: Boolean(value),
    }));
  };

  const handleToggleIsActiveCheckBox = (e, value) => {
    if (panelistData?.isActive == false) {
      panelistData.isActive = true;
    } else {
      panelistData.isActive = false;
    }
    const { name } = e.target;
    setPanelistData((prev) => ({
      ...prev,
      [name]: Boolean(value),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPanelistData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const zipValidateMatch = (value, duplicate) => {
    let zipPatternObjects = validationPatterns.filter((e) =>
      e.countryids.find((l) => l === panelistData.countryId)
    );
    let regexForZip = new RegExp(zipPatternObjects[0].patterns[0]);
    if (!regexForZip.test(value.toUpperCase())) {
      duplicate["zipCode"] =
        "Please enter valid postal code in this format:" +
        zipPatternObjects[0].message;
      setvalidationError((prev) => ({
        ...prev,
        ["zipCode"]:
          "Please enter valid postal code in this format:" +
          zipPatternObjects[0].message,
      }));
    }
  };

  const handleValidInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Input Changed:", name, value);
    let duplicate = { ...validationError };
    setPanelistData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setvalidationError((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "zipCode") {
      if (e.target.value === "") {
        duplicate["zipCode"] = "Zipcode is required";
        setvalidationError((prev) => ({
          ...prev,
          [name]: "Zipcode is required",
        }));
      } else {
        zipValidateMatch(e.target.value, duplicate);
      }
    }
  };

  const handleUpdateSubmit = () => {
    let duplicate = { ...validationError };

    if (panelistData.email === "") {
      duplicate["email"] = "Email Id is required";
    } else if (panelistData.email !== "") {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(panelistData.email)
      ) {
        duplicate["email"] = "Invalid Email Id";
      }
    }
    if (panelistData.zipCode === "") {
      duplicate["zipCode"] = "Zipcode is required";
    } else if (panelistData.zipCode !== "") {
      zipValidateMatch(panelistData.zipCode, duplicate);
    }

    setvalidationError(duplicate);

    const noError = Object.values(duplicate).every(
      (x) => x === null || x === ""
    );
    if (!noError) {
      return;
    }

    const requestData = {
      email: panelistData.email,
      dob: moment(panelistData.dob).format("MM/DD/YYYY"),
      zip: panelistData.zipCode,
      isEmailVerified: panelistData.isEmailVerified,
      unsubscribe: panelistData.unsubscribe,
      panelistId: parseInt(id),
      isActive: panelistData.isActive,
      isSampleAllow: panelistData.isSampleAllow,
      userType: selectedUserType.userType,
      deleteReason: panelistData.deleteReason,
    };
    setIsDisableUpdate(true);
    updatePanelist(requestData)
      .then((res) => {
        if (res?.data) {
          setAlert(res.data.message);
          setIsDisableUpdate(false);

          getPanelistById(panelistId)
            .then((res) => {
              if (res?.status === 200) {
                res.data.data[0].dob = moment(res.data.data[0]?.dob).format(
                  "YYYY-MM-DD"
                );
                res.data.data[0].joinDate = moment(
                  res.data.data[0]?.joinDate
                ).format("YYYY-MM-DD");

                res.data.data[0].emailVerifyDate =
                  res.data.data[0]?.emailVerifyDate != null
                    ? moment(res.data.data[0]?.emailVerifyDate).format(
                        "YYYY-MM-DD"
                      )
                    : null;
                res.data.data[0].unsubscribeDate =
                  res.data.data[0]?.unsubscribeDate != null
                    ? moment(res.data.data[0]?.unsubscribeDate).format(
                        "YYYY-MM-DD"
                      )
                    : null;

                setPanelistData(res.data.data[0]);
              }
            })
            .catch((err) => {
              // console.log(err);
              setAlert("Somthing went wrong");
            });
        }
      })
      .catch((err) => {
        // console.log(err);
        setAlert("Somthing went wrong");
      });
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const renderSurveyTimeCalculation = (startDate, endDate) => {
    if (endDate != null) {
      let totalMinutes = moment
        .utc(endDate)
        .diff(moment.utc(startDate), "minutes");
      let totalSeconds = moment
        .utc(endDate)
        .diff(moment.utc(startDate), "seconds");

      const minutesInDay = 24 * 60;
      let days = Math.floor(totalMinutes / minutesInDay);
      let remainingMinutes = totalMinutes % minutesInDay;
      let hours = Math.floor(remainingMinutes / 60);
      let minutes = remainingMinutes % 60;

      let combineString = "";
      if (days > 0) {
        combineString += days + " days ,";
      }
      if (hours > 0) {
        combineString += hours + " hrs ,";
      }
      if (minutes > 0) {
        combineString += minutes + " min ";
      }

      if (combineString === "") {
        combineString += totalSeconds + " seconds ";
      }
      return combineString;
    } else {
      return "--";
    }
  };

  const handleCopy = () => {
    copyToClipboard(panelistData.firstName, (message) => {
      setAlert(message);
    });
  };
  const handleEmailCopy = () => {
    copyToClipboard(panelistData.email, (message) => {
      setAlert(message);
    });
  };

  return (
    <>
      <SnackBarAlert
        id="divSnackBar"
        open={alert}
        handleClose={handleCloseAlert}
      />
      <Stack direction={"row"} alignItems={"flex-start"}>
        <Paper elevation={2} sx={{ my: 2, p: 2, mr: 2, width: "100%" }}>
          <div className="panelistFormDiv">
            <Accordion
              sx={{ my: 1, p: 0.5, mr: 1, width: "100%" }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography variant="h6">
                  Panelist Personal Details - ({panelistId})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columnSpacing={{ xs: 2, md: 15 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mb: 4 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"firstName"}
                      className="copiedInput"
                      value={panelistData.firstName}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                        pointerEvents: "none",
                      }}
                      label={"First Name"}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Copy to clipboard">
                            <IconButton
                              onClick={handleCopy}
                              style={{ pointerEvents: "auto" }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"lastName"}
                      value={panelistData.lastName}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Last Name"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="gender-label">Gender</InputLabel> */}
                      {/* <Select labelId="gender-label" id="gender" value={panelistData.gender} label="Gender"
                        className="disableField"
                        onChange={(event) =>
                          setPanelistData((prevState) => ({
                            ...prevState,
                            gender: event.target.value,
                          }))}>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select> */}

                      <TextField
                        labelId="gender-label"
                        id="gender"
                        fullWidth
                        multiline
                        name={"Gender"}
                        value={panelistData.gender}
                        type={"text"}
                        className="disableField"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "2px solid #f0f0f0",
                          },
                        }}
                        label="Gender"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      id="txtDob"
                      fullWidth
                      name={"dob"}
                      InputProps={{
                        inputProps: { max: moment().format("YYYY-MM-DD") },
                      }}
                      value={panelistData.dob}
                      type={"date"}
                      onChange={handleInputChange}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"DOB"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Age"}
                      value={panelistData.age}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Age"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"email"}
                      value={panelistData.email}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Email ID"}
                      error={Boolean(validationError.email)}
                      helperText={validationError.email}
                      onChange={handleValidInputChange}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Copy to clipboard">
                            <IconButton
                              onClick={handleEmailCopy}
                              style={{ pointerEvents: "auto" }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Previous Email ID"}
                      value={panelistData.prevEmail}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Previous Email ID"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"zipCode"}
                      value={panelistData.zipCode}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Zipcode"}
                      error={Boolean(validationError.zipCode)}
                      helperText={validationError.zipCode}
                      onChange={handleValidInputChange}
                      inputProps={{ maxLength: zipMaxLength }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Mobile no"}
                      value={panelistData.mobile}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label="Mobile No"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <div className="disableField">
                      <AsyncDropdown
                        value={selectedCountry}
                        options={countries}
                        objKey={"countryName"}
                        stylized
                        label={"Country"}
                        handleChange={(value) =>
                          handleDropDownChange(value, dropDowns.COUNTRY)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <div className="disableField">
                      <AsyncDropdown
                        value={selectedLanguage}
                        options={languages}
                        objKey={"language"}
                        stylized
                        label={"Language"}
                        handleChange={(value) =>
                          handleDropDownChange(value, dropDowns.LANGUAGE)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"currency"}
                      value={panelistData.currency}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Currency"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"address"}
                      value={panelistData.address}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Address"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"state"}
                      value={panelistData.state}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"State"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"city"}
                      value={panelistData.city}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"City"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"joinIp"}
                      value={panelistData.joinIp}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Join Ip"}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"joinDate"}
                      value={panelistData.joinDate}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Join Date"}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"emailVerifyDate"}
                      className="disableField"
                      value={panelistData.emailVerifyDate}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Email Verify Date"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"sourceName"}
                      value={panelistData?.sourceName}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Source"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"device"}
                      className="disableField"
                      value={panelistData.device}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Device Type"}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"UnsubscribeDate"}
                      value={panelistData?.unsubscribeDate}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Unsubscribe Date"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Reconcile"}
                      value={reconcile}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Reconcile"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Reconcile Rate"}
                      value={reconcileRate}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Reconcile Rate"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Reconcile Cost"}
                      value={Points.totalReconcilePoints}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Reconcile Cost"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Revenue"}
                      value={revenue}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Revenue"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Total Cost"}
                      value={totalcost}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Total Cost"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Profit"}
                      value={profit}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Profit"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Terminate Cost"}
                      value={Points.totalTerminateCost}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Terminate Cost"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"emailScore"}
                      value={panelistData?.emailScore}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Email Score"}
                    />
                  </Grid>

                  {/* <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"visitorID"}
                      value={panelistData?.visitorID}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Visitor ID"}
                      InputLabelProps={{ shrink: true }}

                    />
                  </Grid> */}

                  <Grid item xs={2} sm={4} md={4}>
                    <div>
                      <AsyncDropdown
                        value={selectedUserType}
                        options={userTypes}
                        objKey={"userType"}
                        stylized
                        label={"User Type"}
                        InputLabelProps={{ shrink: true }}
                        id="divUserType"
                        handleChange={(value) =>
                          handleDropDownChange(value, dropDowns.USERTYPE)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Conversion"}
                      value={conversion}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Conversion"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"level"}
                      value={panelistData?.levelId}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Level"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Tooltip
                      title={panelistData?.deleteReason || ""}
                      placement="top"
                      disableHoverListener={!panelistData?.deleteReason} // Disables Tooltip when deleteReason is empty
                    >
                      <div>
                        <TextField
                          fullWidth
                          name="deleteReason"
                          value={panelistData?.deleteReason || ""}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "2px solid #f0f0f0",
                            },
                          }}
                          label="Reason"
                          onChange={handleValidInputChange}
                        />
                      </div>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={4} sm={12} md={12}>
                    <Checkbox
                      id="checkEmailVerify"
                      onChange={handleToggleCheckBox}
                      checked={panelistData?.isEmailVerified}
                    />
                    <span>Email Verified</span>
                    <Checkbox
                      id="checkSubscribe"
                      onChange={handleToggleSubscribeCheckBox}
                      checked={panelistData?.unsubscribe}
                    />
                    <span>Unsubscribe</span>

                    <Checkbox
                      id="checkSampleAllow"
                      onChange={handleToggleSampleAllowCheckBox}
                      checked={panelistData?.isSampleAllow}
                    />
                    <span>Sample Allow</span>
                    <Checkbox
                      id="checkSubscribe"
                      onChange={handleToggleIsActiveCheckBox}
                      checked={panelistData?.isActive}
                    />
                    <span>Active</span>

                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columnSpacing={{ xs: 2, md: 15 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{ mb: 4 }}
                    >
                      <Grid item xs={4} sm={4} md={4}></Grid>
                      <Grid item xs={4} sm={4} md={4}></Grid>

                      <Grid item xs={4} sm={4} md={4}>
                        {!isDisableUpdate ? (
                          <ConfirmAlert
                            onClickEvent={() => handleUpdateSubmit()}
                            msg={"Are you sure want to update panellist?"}
                          >
                            <LoadingButton
                              variant="contained"
                              sx={{ px: 4, textTransform: "capitalize", my: 1 }}
                            >
                              Update Panelist
                            </LoadingButton>
                          </ConfirmAlert>
                        ) : (
                          <LoadingButton
                            variant="contained"
                            sx={{ px: 4, textTransform: "capitalize", my: 1 }}
                            disabled={isDisableUpdate}
                          >
                            Update Panelist
                          </LoadingButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid></Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {panelistData?.countryId === 4 ? (
              <Accordion sx={{ my: 2, p: 0.5, mr: 2, width: "100%" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-content"
                  id="panel-header"
                >
                  <Typography variant="h6">Panelist W9Form Details:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="data-table">
                    <CustomDataTable
                      columns={w9FormColumns}
                      data={W9FormDetail}
                      pagination={true}
                      progressPending={pending}
                      highlightOnHover
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}

            <Accordion sx={{ my: 2, p: 0.5, mr: 2, width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography variant="h6">Panelist Points Details:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columnSpacing={{ xs: 2, md: 15 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mb: 4 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"totalPoints"}
                      value={Points.totalPoints}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Total Earned Points"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"totalSurveyPoints"}
                      className="disableField"
                      value={Points.totalSurveyPoint}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Survey Complete Points"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"totalRefferalPoint"}
                      className="disableField"
                      value={Points.totalRefferalPoint}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Referral Earned Point"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"TotalMiniSurveyPoint"}
                      value={Points.totalMiniSurveyPoint}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Mini Survey Earned Point"}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"totalTerminatePoint"}
                      className="disableField"
                      value={Points.totalTerminatePoint}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Survey Terminate Points"}
                    />
                  </Grid>
                </Grid>
                <div className="data-table">
                  <CustomDataTable
                    columns={columns}
                    data={pointsDetail}
                    pagination={true}
                    progressPending={pending}
                    highlightOnHover
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ my: 2, p: 0.5, mr: 2, width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography variant="h6">
                  Survey Participation Details:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columnSpacing={{ xs: 2, md: 15 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mb: 4 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name={"Total Attempts"}
                      value={SurveyPoints.totalAttempts}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Total Attempts"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Completes"}
                      value={SurveyPoints.completes}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Completes"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Terminates"}
                      value={SurveyPoints.terminates}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Terminates"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Quota Full"}
                      value={SurveyPoints.quotaFull}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Quota Full"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"InCompletes"}
                      value={SurveyPoints.inCompletes}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"InCompletes"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Reconcile"}
                      value={SurveyPoints?.reconcile}
                      className="disableField"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Reconcile"}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
                <div className="data-table">
                  <CustomDataTable
                    columns={surveyColumns}
                    data={SurveyPointsDetail}
                    pagination={true}
                    progressPending={pending}
                    highlightOnHover
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ my: 2, p: 0.5, mr: 2, width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography variant="h6">Redemption Details:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columnSpacing={{ xs: 2, md: 15 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mb: 4 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Total Points"}
                      className="disableField"
                      value={
                        RedemptionPoints.totalPoints > 0
                          ? RedemptionPoints.totalPoints +
                            "($" +
                            Math.round(RedemptionPoints.totalPoints) /
                              redemptionPointDivider +
                            ")"
                          : 0
                      }
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Total Point"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Points Redeemed"}
                      className="disableField"
                      value={
                        RedemptionPoints.pointredeemed > 0
                          ? RedemptionPoints.pointredeemed +
                            "($" +
                            Math.round(RedemptionPoints.pointredeemed) /
                              redemptionPointDivider +
                            ")"
                          : 0
                      }
                      // value={RedemptionPoints.pointredeemed}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Points Redeemed"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Balance Points"}
                      className="disableField"
                      value={
                        RedemptionPoints.balancepoints +
                        "($" +
                        Math.round(RedemptionPoints.balancepoints) /
                          redemptionPointDivider +
                        ")"
                      }
                      // value={RedemptionPoints.balancepoints}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Balance Points"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Approved Point"}
                      className="disableField"
                      value={
                        RedemptionPoints.approvedPoints > 0
                          ? RedemptionPoints.approvedPoints +
                            "($" +
                            Math.round(RedemptionPoints.approvedPoints) /
                              redemptionPointDivider +
                            ")"
                          : 0
                      }
                      // value={RedemptionPoints.approvedPoints}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Approved Point"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Rejected Point"}
                      className="disableField"
                      value={
                        RedemptionPoints.rejectedPoints > 0
                          ? RedemptionPoints.rejectedPoints +
                            "($" +
                            Math.round(RedemptionPoints.rejectedPoints) /
                              redemptionPointDivider +
                            ")"
                          : 0
                      }
                      // value={RedemptionPoints.rejectedPoints}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Rejected Point"}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextField
                      fullWidth
                      multiline
                      name={"Pending Point"}
                      className="disableField"
                      value={
                        RedemptionPoints.pendingPoints > 0
                          ? RedemptionPoints.pendingPoints +
                            "($" +
                            Math.round(RedemptionPoints.pendingPoints) /
                              redemptionPointDivider +
                            ")"
                          : 0
                      }
                      // value={RedemptionPoints.pendingPoints}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #f0f0f0",
                        },
                      }}
                      label={"Pending Point"}
                    />
                  </Grid>
                </Grid>
                <div className="data-table redeemTable">
                  <CustomDataTable
                    columns={redeemColumns}
                    data={RedemptionPointsDetail}
                    pagination={true}
                    progressPending={pending}
                    highlightOnHover
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </Paper>
      </Stack>
    </>
  );
};

export default PanelistForm;
