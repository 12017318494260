import { Done, Close, Message, Edit, PauseCircle } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  Tab,
  Tabs,
  Box,
  TextField,
  Paper,
  Grid,
  Tooltip,
} from "@mui/material";
import { TabPanel, TabContext, LoadingButton } from "@mui/lab";
import moment from "moment";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import ConfirmAlert from "../../utils/ConfirmAlert";
import TopHeading from "../../utils/TopHeading/TopHeading";
import AnimatedPage from "../animatedPage/AnimatedPage";
import Hoc from "../HOC/Hoc";
import "../RedemptionRequest/Style.css";
import {
  approveRequest,
  getActivepanels,
  getRedemptionData,
  getRedeemptionRequestsByFilter,
} from "./Redemption.api";
import ConfRedemption from "./ConfRedemption";
import CustomDataTable from "../../views/CustomTable/CustomDataTable";
import { SnackBarAlert } from "../../utils/SnackBar";
import CustomTooltip from "../../../src/utils/CustomTooltip";

const Redemption = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;

  //redux
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const isPanelMember = useSelector(
    (state) => state.panelMemberPermissions.isPanelMember
  );
  const memberPermissions = useSelector(
    (state) => state.panelMemberPermissions.memberPermissions
  );

  //states
  const [activePanels, setActivePanels] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState({});
  const [pageloading, setPageloading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [requests, setrequests] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [rejectedRequests, setRejectedRequests] = useState([]);
  const [editId, seteditId] = useState({});
  const [selectedItem, setselectedItem] = useState({});
  const [value, setValue] = useState("Pending");
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isApprovedBtn, setIsApprovedBtn] = useState(true);
  const [failedRequests, setFailedRequests] = useState([]);
  const [holdRequests, setHoldRequests] = useState([]);

  const [isPauseBtn, setIsPauseBtn] = useState(true);
  const [filterData, setFilterData] = useState({
    startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    maxDate: moment().format("YYYY-MM-DD"),
  });
  const [panelGuid, setPanelGuid] = useState("");
  const [actionStatus, setActionStatus] = useState("reject");
  const [confirmMessage, setConfirmMessage] = useState("Disapprove");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    redemptionDataAfterPanelGuid(newValue);
  };
  const handleDropDownChange = (value, dropDownName) => {
    switch (dropDownName) {
      case "PANEL":
        setSelectedPanel(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getActivepanels(adminId)
      .then((res) => {
        if (res?.status === 200) {
          const { data } = res.data;
          setActivePanels(data);
          setSelectedPanel(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setActivePanels([]);
      setSelectedPanel({});
      setDataUpdated(false);
      setPendingRequests([]);
      setApprovedRequests([]);
      setRejectedRequests([]);
      setFailedRequests([]);
      setHoldRequests([]);
    };
  }, [adminId]);

  useEffect(() => {
    if (selectedPanel && selectedPanel?.panelId && selectedPanel?.panelGuid) {
      setPageloading(true);
      refreshRedeemptionWithFilter(selectedPanel?.panelId, null);
      redemptionDataAfterPanelGuid(value);
    }
    return () => {
      setrequests([]);
    };
  }, [selectedPanel, dataUpdated]);

  const refreshRedeemptionWithFilter = (panelId, utid) => {
    let filterDataRequest = {
      startDate: filterData.startDate,
      endDate: filterData.endDate,
    };
    getRedeemptionRequestsByFilter(panelId, filterDataRequest)
      .then((res) => {
        if (utid != null) {
          disableEnableRedemption(utid, "enable");
        }
        if (res?.status === 200) {
          const { data } = res.data;
          setPendingRequests(data.filter((req) => req.status === "pending"));
          setApprovedRequests(data.filter((req) => req.status === "approved"));
          setRejectedRequests(
            data.filter((req) => req.status === "disapproved")
          );
          setFailedRequests(data.filter((req) => req.status === "failed"));
          setHoldRequests(data.filter((req) => req.status === "hold"));
        }
      })
      .catch((err) => {
        disableEnableRedemption(utid, "enable");
        console.log("err", err.message);
      })
      .finally(() => {
        setPageloading(false);
        setPending(false);
        disableEnableRedemption(utid, "enable");
      });
  };

  const approveRequestHandler = (id, utid, panelId) => {
    setPageloading(true);
    setIsApprovedBtn(true);
    disableEnableRedemption(utid, "disable");
    const data = {
      utid: utid,
      status: "approved",
      redemptionStatus: "complete",
      approvedBy: adminId,
      updatedBy: adminId,
    };
    approveRequest(id, data, selectedPanel?.panelGuid)
      .then((res) => {
        disableEnableRedemption(utid, "disable");
        if (res?.status === 200) {
          setDataUpdated(!dataUpdated);
          if (res?.data?.data?.status == "approved") {
            setAlert("Redemption approved Successfully");
            setPageloading(true);
            refreshRedeemptionWithFilter(panelId, utid);
          } else if (res?.data?.message == "Invalid IP") {
            setAlert(res?.data?.message);
            setPageloading(true);
            refreshRedeemptionWithFilter(panelId, utid);
          }
        }
        if (res?.status === undefined) {
          setPageloading(true);
          refreshRedeemptionWithFilter(panelId, utid);
        }
      })
      .catch((err) => {
        console.log(err);
        disableEnableRedemption(utid, "disable");
      })
      .finally(() => {
        disableEnableRedemption(utid, "disable");
      });
    console.log("Approved");
  };

  const holdRequestHandler = (id, utid, panelId) => {
    setPageloading(true);
    setIsApprovedBtn(true);
    disableEnableRedemption(utid, "disable");
    const data = {
      utid: utid,
      status: "hold",
      redemptionStatus: "complete",
      approvedBy: adminId,
      updatedBy: adminId,
    };
    approveRequest(id, data, selectedPanel?.panelGuid)
      .then((res) => {
        disableEnableRedemption(utid, "disable");
        if (res?.status === 200) {
          // disableEnableRedemption(utid, 'disable');
          setDataUpdated(!dataUpdated);
          if (res?.data?.data?.status == "hold") {
            setAlert("Redemption hold Successfully");
            setPageloading(true);
            refreshRedeemptionWithFilter(panelId, utid);
          } else if (res?.data?.message == "Invalid IP") {
            setAlert(res?.data?.message);
            setPageloading(true);
            refreshRedeemptionWithFilter(panelId, utid);
          }
        }
        if (res?.status === undefined) {
          // disableEnableRedemption(utid, 'disable');
          setPageloading(true);
          refreshRedeemptionWithFilter(panelId, utid);
        }
      })
      .catch((err) => {
        disableEnableRedemption(utid, "disable");
        console.log(err);
      })
      .finally(() => {
        disableEnableRedemption(utid, "disable");
      });
    console.log("Approved");
  };

  const disableEnableRedemption = (utid, type) => {
    var redemptionStatus = document.getElementsByClassName("showDis");
    for (var i = 0; i < redemptionStatus.length; i++) {
      if (type == "disable") {
        redemptionStatus[i].style.pointerEvents = "none";
        if (document.getElementById("parentRedemptio")?.classList != null) {
          document
            .getElementById("parentRedemptio")
            .classList.remove("divFilter1");
          document.getElementById("parentRedemptio").classList.add("divFilter");
        }

        if (document.getElementById("parentHoldRedemptio")?.classList != null) {
          document
            .getElementById("parentHoldRedemptio")
            .classList.remove("divFilter1");
          document
            .getElementById("parentHoldRedemptio")
            .classList.add("divFilter");
        }
      } else if (type == "enable") {
        redemptionStatus[i].style.pointerEvents = "unset";
        if (document.getElementById("parentRedemptio")?.classList != null) {
          document
            .getElementById("parentRedemptio")
            .classList.remove("divFilter");
          document
            .getElementById("parentRedemptio")
            .classList.add("divFilter1");
        }

        if (document.getElementById("parentHoldRedemptio")?.classList != null) {
          document
            .getElementById("parentHoldRedemptio")
            .classList.remove("divFilter");
          document
            .getElementById("parentHoldRedemptio")
            .classList.add("divFilter1");
        }
      }
    }
  };

  const redemptionDataAfterPanelGuid = (tabName) => {
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: "ID",
          selector: (row) => row.id,
          sortable: true,
          minWidth: "70px",
          maxWidth: "100px",
          omit: tabName == "Pending" ? true : false,
        },
        {
          name: <div>Panelist Id</div>,
          selector: (row) => row.panelistId,
          sortable: true,
          cell: (row) => (
            <Stack direction={"row"}>
              <Link
                to={`/admin/panelist-detail/${row.panelistId}`}
                target="_blank"
              >
                {row.panelistId}
              </Link>
            </Stack>
          ),
        },
        {
          name: <div>Email</div>,
          selector: (row) => (
            <Tooltip title={row.email} placement="top">
              <div>{row.email}</div>
            </Tooltip>
          ),
          sortable: true,
          minWidth: "120px",
          maxWidth: "120px",
        },
        {
          name: (
            <Tooltip title="Redeem Count" placement="top">
              <div>Count</div>
            </Tooltip>
          ),
          selector: (row) => row.redeemCount,
          sortable: true,
          minWidth: "90px",
          maxWidth: "90px",
        },
        {
          name: <div>Reward</div>,
          selector: (row) => (
            <Tooltip title={row.rewardName} placement="top">
              <div>{row.rewardName}</div>
            </Tooltip>
          ),
          sortable: true,
          minWidth: "120px",
          maxWidth: "120px",
        },
        {
          name: (
            <Tooltip title="Reconcile Rate" placement="top">
              <div>RRate</div>
            </Tooltip>
          ),
          selector: (row) => (row.RRate == null ? "0.00" : row.RRate),
          sortable: true,
          minWidth: "70px",
          maxWidth: "70px",
          // omit:(tabName=="Pending"?false:true)
        },
        {
          name: "Source",
          selector: (row) => (row?.sourceName == null ? "--" :
            <Tooltip title={row?.sourceName} placement="top">
              <div>{row?.sourceName}</div>
            </Tooltip>
          ),
          sortable: true,
          minWidth: "100px",
          maxWidth: "100px",
          // omit:(tabName=="Pending"?false:true)
        },
        {
          name: "Points",
          selector: (row) => row.point,
          sortable: true,
        },
        // {

        //     name: 'Paid Points',
        //     selector: (row) => (row.paidpoints == null ? "0" : row.paidpoints),
        //     sortable: true,
        //     minWidth: '150px',
        //     maxWidth: '150px',
        //     omit:(tabName=="Pending"?true:false)

        // },
        {
          name: "Amount",
          selector: (row) => row.amount,
          sortable: true,
          cell: (row) => `${row.amount} ${row.currencyCode}`,
        },
        {
          name: <div>USD Amount</div>,
          selector: (row) => row.USDAmount,
          sortable: true,
          minWidth: "80px",
          maxWidth: "80px",
        },
        {
          name: (
            <Tooltip title="Conversion Rate" placement="top">
              <div className="conversion-header">CNV Rate</div>
            </Tooltip>
          ),
          selector: (row) => row.conversionRate,
          sortable: true,
          minWidth: "80px",
          maxWidth: "80px",
         omit: tabName === "Pending"
        },
        {
          name: (
            <Tooltip title="Visitor Count" placement="top">
              <div className="conversion-header">V Count</div>
            </Tooltip>
          ),
          selector: (row) => (
            <CustomTooltip
              title={row.visitorId || "Visitor ID Not Found"}
              childrenText={row.visitorCount || "--"}
              copiedText
            />
          ),
          sortable: true,
          minWidth: "80px",
          maxWidth: "80px",
        },
        // {
        //     name: (
        //         <Tooltip title="Paid Conversion" placement="top">
        //         <div>Paid CNV</div>
        //         </Tooltip>
        //       ),

        //     selector: (row) => (row.paidConversion == null ? "0.00" : row.paidConversion.toFixed(2)),
        //     sortable: true,
        //     omit:(tabName=="Pending"?true:false)
        // },
        {
          name: (
            <Tooltip title="Redemption Status" placement="top">
              <div>Status</div>
            </Tooltip>
          ),
          selector: (row) => (
            <Tooltip title={row.redemptionStatus} placement="top">
            <div>{row.redemptionStatus}</div>
          </Tooltip>
          ),
          sortable: true,
          minWidth: "100px",
          maxWidth: "100px",
        },
        {
          name: <div>Reason</div>,
          selector: (row) =>
            row?.disapprovedReason == null ? (
              "--"
            ) : (
              <CustomTooltip
                title={row.disapprovedReason}
                childrenText={row.disapprovedReason}
              />
            ),
          sortable: true,
          minWidth: "100px",
          maxWidth: "100px",
          omit: tabName !== "Hold" ? true : false,
        },
        {
          name: <div>Created On</div>,
          selector: (row) => moment(row?.createdAt).format("YYYY-MM-DD"),
          sortable: true,
          minWidth: "120px",
          maxWidth: "120px",
        },
        {
          name: <div>Updated On</div>,
          selector: (row) => moment(row?.updatedAt).format("YYYY-MM-DD"),
          sortable: true,
          minWidth: "120px",
          maxWidth: "120px",
          omit: tabName == "Pending" ? true : false,
        },

        {
          name: (
            <Tooltip title="Redemption Action" placement="top">
              <div>Action</div>
            </Tooltip>
          ),
          selector: (row) => row.status,
          sortable: true,
          cell: (row) =>
            row.status === "pending" || row.status === "hold" ? (
              <>
                {isApprovedBtn ? (
                  <div className="redemption_Status">
                    <Stack direction={"row"}>
                      {isApprovedBtn ? (
                        <div id={"redeem" + row.id} className="showDis">
                          {" "}
                          <ConfirmAlert
                            msg={
                              "Are you sure you want to approved Redemption?"
                            }
                            onClickEvent={() => {
                              {
                                approveRequestHandler(
                                  row.id,
                                  row.utid,
                                  row.panelId
                                );
                              }
                            }}
                          >
                            <Tooltip title="Approved">
                              <IconButton>
                                <Done />
                              </IconButton>
                            </Tooltip>
                          </ConfirmAlert>
                        </div>
                      ) : (
                        <Tooltip title="Approved">
                          {" "}
                          <IconButton disabled={false}>
                            <Done disabled={false} />
                          </IconButton>{" "}
                        </Tooltip>
                      )}

                      <Tooltip title="Disapprove">
                        <IconButton
                          className="showDis"
                          id={"divCross" + row.utid}
                          onClick={() => {
                            seteditId(row.id);
                            setselectedItem(row);
                            setActionStatus("reject");
                            setConfirmMessage("Disapprove");
                            handleOpenEditModal();
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                      {row.status == "hold" ? (
                        <Tooltip title="Edit Reason">
                          <IconButton
                            className="showDis"
                            id={"divCross" + row.utid}
                            onClick={() => {
                              seteditId(row.id);
                              setselectedItem(row);
                              setActionStatus("hold");
                              setConfirmMessage("Hold");
                              handleOpenEditModal();
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {isPauseBtn ? (
                        // <div id={'redeemHold' + row.id} className={(row.status !== "hold" ? 'showDis' : 'hideDisplay')}> <ConfirmAlert
                        //     msg={"Are you sure you want to hold Redemption?"}
                        //     onClickEvent={() => { { holdRequestHandler(row.id, row.utid,row.panelId) } }}
                        // >
                        //      <Tooltip title={row.status!== "hold" ? "Hold" : ""}>
                        //         <IconButton>
                        //             <PauseCircle />
                        //         </IconButton>
                        //     </Tooltip>
                        // </ConfirmAlert>
                        // </div>
                        <div
                          id={"redeemHold" + row.id}
                          className={
                            row.status !== "hold" ? "showDis" : "hideDisplay"
                          }
                        >
                          <Tooltip title={row.status !== "hold" ? "Hold" : ""}>
                            <IconButton
                              className="showDis"
                              onClick={() => {
                                seteditId(row.id);
                                setselectedItem(row);
                                setActionStatus("hold");
                                setConfirmMessage("Hold");
                                handleOpenEditModal();
                              }}
                            >
                              <PauseCircle />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <Tooltip title="Pause">
                          {" "}
                          <IconButton disabled={false}>
                            <PauseCircle disabled={false} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              row.status
            ),
          minWidth: "120px",
          maxWidth: "120px",
        },
      ]);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const safeToString = (value) => (value ? value.toString().toLowerCase() : "");

  const filteredPendingData = pendingRequests.filter((item) => {
    return (
      safeToString(item.panelistId).includes(searchQuery.toLowerCase()) ||
      safeToString(item.email).includes(searchQuery.toLowerCase()) ||
      safeToString(item.rewardName).includes(searchQuery.toLowerCase())
    );
  });

  const filteredApprovedData = approvedRequests.filter((item) => {
    return (
      safeToString(item.panelistId).includes(searchQuery.toLowerCase()) ||
      safeToString(item.email).includes(searchQuery.toLowerCase()) ||
      safeToString(item.rewardName).includes(searchQuery.toLowerCase())
    );
  });

  const filteredRejectedData = rejectedRequests.filter((item) => {
    return (
      safeToString(item.panelistId).includes(searchQuery.toLowerCase()) ||
      safeToString(item.email).includes(searchQuery.toLowerCase()) ||
      safeToString(item.rewardName).includes(searchQuery.toLowerCase())
    );
  });

  const filterFailedData = failedRequests.filter((item) => {
    return (
      safeToString(item.panelistId).includes(searchQuery.toLowerCase()) ||
      safeToString(item.email).includes(searchQuery.toLowerCase()) ||
      safeToString(item.rewardName).includes(searchQuery.toLowerCase())
    );
  });

  const filteredHoldData = holdRequests.filter((item) => {
    return (
      safeToString(item.panelistId).includes(searchQuery.toLowerCase()) ||
      safeToString(item.email).includes(searchQuery.toLowerCase()) ||
      safeToString(item.rewardName).includes(searchQuery.toLowerCase())
    );
  });

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearchFilter = (e) => {
    setPending(true);
    setPageloading(true);
    refreshRedeemptionWithFilter(selectedPanel?.panelId, null);
  };

  const downloadRedemptionData = () => {
    getRedemptionData(
      value.toLowerCase(),
      filterData.startDate,
      filterData.endDate
    )
      .then((res) => {
        const redemptionValue = res.data.data; // Your data array
        if (redemptionValue.length === 0) {
          setAlert("No data available");
          return;
        }

        // Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(redemptionValue);

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Redemption");

        // Export the workbook as an Excel file
        XLSX.writeFile(workbook, `${value} Redemption.xlsx`);
      })
      .catch((error) => {});
  };
  const conditionalRowStyles = [
    {
      when: (row) => row.redeemCount === 1,
      style: {
        backgroundColor: "rgb(255 0 0 / 14%)", // Apply your desired highlight color
      },
    },
  ];
  return (
    <>
      <div>
        <SnackBarAlert
          id="divSnackBar"
          open={alert}
          handleClose={handleCloseAlert}
        />
        <AnimatedPage>
          <TopHeading
            heading="Redemption Request"
            showButton={Boolean(false)}
            openModal={handleOpenModal}
          />

          <Paper sx={{ px: 6, py: 1, mx: 2, mb: 2 }} elevation={1}>
            <Stack
              direction={"row"}
              sx={{ mb: 2, my: 2 }}
              spacing={2}
              alignItems={"flex-start"}
            >
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3} md={3}>
                  <AsyncDropdown
                    handleChange={(value) =>
                      handleDropDownChange(value, "PANEL")
                    }
                    value={selectedPanel}
                    options={activePanels}
                    objKey={"panelName"}
                    stylized
                    label={"Select Panel"}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <TextField
                    fullWidth
                    InputProps={{ inputProps: { max: filterData.maxDate } }}
                    name={"startDate"}
                    value={filterData.startDate}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #f0f0f0",
                      },
                    }}
                    label={"Start Date"}
                    type={"date"}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <TextField
                    fullWidth
                    InputProps={{ inputProps: { max: filterData.maxDate } }}
                    name={"endDate"}
                    value={filterData.endDate}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #f0f0f0",
                      },
                    }}
                    label={"End Date"}
                    type={"date"}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={1} sm={1} md={1}>
                  <LoadingButton
                    disabled={
                      moment(filterData.endDate).diff(
                        moment(filterData.startDate),
                        "days"
                      ) < 0
                    }
                    onClick={handleSearchFilter}
                    variant="contained"
                    sx={{ px: 4, textTransform: "capitalize", my: 1 }}
                  >
                    Search
                  </LoadingButton>
                </Grid>
              </Grid>
            </Stack>
          </Paper>

          {selectedPanel?.panelGuid ? (
            <ConfRedemption
              open={openEditModal}
              loading={loading}
              // type="ADD"
              hadnleCloseModal={handleCloseEditModal}
              setSuccess={setSuccess}
              setError={setError}
              setAlert={setAlert}
              dataUpdated={dataUpdated}
              setDataUpdated={setDataUpdated}
              selectedItem={selectedItem}
              actionStatus={actionStatus}
              confirmMessage={confirmMessage}
              panelGuid={selectedPanel?.panelGuid}
            />
          ) : (
            ""
          )}
          <TabContext value={value} className="align-items-center">
            <Box sx={{ mx: 4, my: 2 }}>
              <Tabs
                onChange={handleChange}
                value={value}
                aria-label="Tabs where selection follows focus"
                selectionFollowsFocus
                className="d-flex justify-content-between align-items-center"
              >
                <Tab label="Pending" value={"Pending"} />
                <Tab label="Approved" value={"Approved"} />
                <Tab label="Disapproved" value={"disapproved"} />
                <Tab label="Failed" value={"Failed"} />
                <Tab label="Hold" value={"Hold"} />

                <button
                  onClick={downloadRedemptionData}
                  className="download-button"
                  type="button"
                >
                  <span className="download-button__text">Download</span>
                  <span className="download-button__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 35 35"
                      id="bdd05811-e15d-428c-bb53-8661459f9307"
                      data-name="Layer 2"
                      class="svg"
                    >
                      <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
                      <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
                      <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
                    </svg>
                  </span>
                </button>
              </Tabs>
              <TabPanel value={"Pending"} index={"Pending"}>
                <Stack spacing={2}>
                  <TextField
                    type="search"
                    value={searchQuery}
                    fullWidth
                    label="Search"
                    name="Search"
                    onChange={handleSearch}
                  />
                  <div id="parentRedemptio">
                    <CustomDataTable
                      columns={columns}
                      data={filteredPendingData}
                      pagination={true}
                      progressPending={pending}
                      highlightOnHover
                      conditionalRowStyles={conditionalRowStyles}
                    />
                  </div>
                </Stack>
              </TabPanel>
              <TabPanel value={"Approved"} index={"Approved"}>
                <Stack spacing={2}>
                  <TextField
                    type="search"
                    value={searchQuery}
                    fullWidth
                    label="Search"
                    name="Search"
                    onChange={handleSearch}
                  />
                  <div>
                    <CustomDataTable
                      columns={columns}
                      data={filteredApprovedData}
                      pagination={true}
                      progressPending={pending}
                      highlightOnHover
                    />
                  </div>
                </Stack>
              </TabPanel>
              <TabPanel value={"disapproved"} index={"disapproved"}>
                <Stack spacing={2}>
                  <TextField
                    type="search"
                    value={searchQuery}
                    fullWidth
                    label="Search"
                    name="Search"
                    onChange={handleSearch}
                  />
                  <div>
                    <CustomDataTable
                      columns={columns}
                      data={filteredRejectedData}
                      pagination={true}
                      progressPending={pending}
                      highlightOnHover
                    />
                  </div>
                </Stack>
              </TabPanel>

              <TabPanel value={"Failed"} index={"Failed"}>
                <Stack spacing={2}>
                  <TextField
                    type="search"
                    value={searchQuery}
                    fullWidth
                    label="Search"
                    name="Search"
                    onChange={handleSearch}
                  />
                  <div>
                    <CustomDataTable
                      columns={columns}
                      data={filterFailedData}
                      pagination={true}
                      progressPending={pending}
                      highlightOnHover
                    />
                  </div>
                </Stack>
              </TabPanel>

              <TabPanel value={"Hold"} index={"Hold"}>
                <Stack spacing={2}>
                  <TextField
                    type="search"
                    value={searchQuery}
                    fullWidth
                    label="Search"
                    name="Search"
                    onChange={handleSearch}
                  />
                  <div id="parentHoldRedemptio">
                    <CustomDataTable
                      columns={columns}
                      data={filteredHoldData}
                      pagination={true}
                      progressPending={pending}
                      highlightOnHover
                      conditionalRowStyles={conditionalRowStyles}
                    />
                  </div>
                </Stack>
              </TabPanel>
            </Box>
          </TabContext>
        </AnimatedPage>
      </div>
    </>
  );
};

export const EnhancedRedemption = Hoc(Redemption, { name: "" });
