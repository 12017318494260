import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import moment from "moment";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { useSelector } from "react-redux";
// import { getAllPanel } from "../panel/panel.api";
import { getActivepanels } from "../managePanel/managePanel.api";
import { updateCampaign, createCampaign, getAllCampaign } from "../campaign/campaign.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";

const ConfUser = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    setEditId,
    editId,
    selectedItem,
    roleSelected,
    miniSurveyCode,

    activePanels,
  } = props;

  // states
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [panels, setPanels] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [filterData, setFilterData] = useState({
    launchDate: moment().subtract("months").format("YYYY-MM-DD"),
  });

  const [userData, setUserData] = useState({
    campaignName: "",
    points: "",
    campaignUrl: "",
    launchDate: "",
  });


  

  useEffect(() => {
    getActivepanels(adminId).then((res) => {
      if (res?.status === 200) {
        const panels = res.data.data;
        setSelectedPanels(panels[0]);
      }
    });
    return () => {
      setSelectedPanels({});
    };
  }, []);

  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length > 0) {
      setUserData({
        campaignName: selectedItem.campaignName || "",
        points: selectedItem.points || "",
        campaignUrl: selectedItem.campaignUrl || "",
        launchDate: setFilterData({
          launchDate: moment(selectedItem.launchDate).format("YYYY-MM-DD") || "",
        }),
      });
    }
    return () => {
      setUserData({});
    };
  }, [selectedItem]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "launchDate") {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePanelDropDownChange = (value, dropDownName) => {
    if (dropDownName === "PANEL") {
      setSelectedPanels(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let panelIds = [];
    if (selectedPanels && selectedPanels.length > 0) {
      selectedPanels.map((item) => panelIds.push(item.panelId));
    }
    setLoading(true);
    let callMethod;
    if (type === "ADD") {
      let newData = {
        campaignName: userData.campaignName,
        points: userData.points,
        campaignUrl: userData.campaignUrl,
        launchDate: filterData.launchDate,
      };
      callMethod = createCampaign(newData);
    } else {
      let data = {
        campaignName: userData.campaignName,
        points: userData.points,
        campaignUrl: userData.campaignUrl,
        launchDate: filterData.launchDate,
      };
      callMethod = updateCampaign(selectedItem.campaignId, data);
    }
    callMethod
      .then((res) => {
        if (!res.data.success) {
          if (res.data.message === "Campaign already exist") {
            setAlert("Campaign already exist");
            setLoading(false);
          }
          return;
        }
        if (res?.status === 201 || res?.status === 200) {
          setSuccess();
          setLoading(false);
          setUserData({
            campaignName: "",
            points: "",
            campaignUrl: "",
            launchDate: "",
          });
          hadnleCloseModal();
        } else {
          setLoading(false);
          hadnleCloseModal();
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong");
      });
    
  };

  return (
    <Dialog fullWidth={"true"} open={open} onClose={hadnleCloseModal}>
      <form onSubmit={handleSubmit}>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle>
          {type === "ADD"
            ? "Add Campaign - Opinion-Edge"
            : "Edit Campaign - Opinion-Edge"}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Box padding={2}>
            <Stack direction={"column"} spacing={2}>
              {type === "ADD" && (
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <AsyncDropdown
                    label={"Panel Name"}
                    disabled={props.disableDropDowsn}
                    value={selectedPanels} // Ensure selectedPanel matches activePanels
                    handleChange={(value) =>
                      handlePanelDropDownChange(value, "PANEL")
                    }
                    name="panelId"
                    options={activePanels} // Options from API
                    objKey={"panelName"} // Key to display in the dropdown
                    stylized
                  />
                </Stack>
              )}
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"text"}
                  label={"Campaign Name"}
                  name="campaignName"
                  required
                  value={userData.campaignName}
                />
                {/* {type === "EDIT" && (
                  <TextField
                    fullWidth
                    label={miniSurveyCode}
                    inputProps={{ readOnly: true }}
                  />
                )} */}
              </Stack>

              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"number"}
                  label={"Points"}
                  name="points"
                  required
                  value={userData.points}
                />
                <TextField
                  fullWidth
                  name={"launchDate"}
                  value={filterData.launchDate}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #f0f0f0",
                    },
                  }}
                  label={"Launch Date"}
                  type={"date"}
                  onChange={handleInputChange}
                />
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"text"}
                  label={"Link"}
                  name="campaignUrl"
                  required
                  value={userData.campaignUrl}
                />
              </Stack>

              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems="flex-end"
              ></Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type={"submit"}
          >
            {type === "ADD" ? "Save" : "Update"}
          </LoadingButton>
          <Button variant="outlined" onClick={hadnleCloseModal}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfUser;
