import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SnackBarAlert } from "../../../utils/SnackBar";
import TopHeading from "../../../utils/TopHeading/TopHeading";
import { getSurveyDetails } from "../survey.api";
import Qualification from "./Qualification/Qualification";
import SurveyForm from "./SurveyForm";
import SurveySteps from "./SurveySteps";
import SurveyEmail from "./Email/SurveyEmail";

const CreateSurvey = () => {
  const [surveySteps, setSurveySteps] = useState(["survey"]);
  const [alert, setAlert] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);

  const [selectedStep, setSelectedStep] = useState(0);

  const [surveyResdata, setSurveyResdata] = useState({});
  const handleChangeSelected = (index) => {
    setSelectedStep(index);
  };
  const location = useLocation();
  const [editData, setEditData] = useState({});

  const [fetchingdata, setFetchingdata] = useState(false);
  const [surveyState, setSurveyState] = useState({});
  useEffect(() => {
    const surveyId = parseInt(location.pathname.split('/')[3]);
    const state = JSON.parse(localStorage.getItem(`surveyState_${surveyId}`));
    setSurveyState(state);
    // Initialize survey steps based on type
    setSurveySteps(["survey", "qualification", "quota", "email"]);
    setFetchingdata(true);
    getSurveyDetails(state?.surveyId ? state?.surveyId : surveyResdata?.id)
      .then((res) => {
        if (res?.status == 200) {
          setEditData(res.data.data);
          return;
        } else if(res?.response.status === 400) {
          setAlert("Invalid Survey ID");
          return;
        }
        
      })
      .catch((err) => {
        setAlert("Something went wrong");
      })
      .finally(() => setFetchingdata(false));

    return () => {
      setSurveySteps(["survey"]); // Reset on unmount
    };
  }, [location, surveySteps.length]);

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const [qualification, setQualification] = useState([
    {
      name: "adsa",
      qualification: "",
      value: "",
      range: {
        from: 0,
        to: 0,
      },
    },
  ]);
  const handleNextStep = () => {
    setSelectedStep((prev) => prev + 1);
  };

  const handleCompleteStep = (step) => {
    let duplicate = [...completedSteps];
    duplicate.push(step);
    setCompletedSteps(duplicate);
    handleNextStep();
  };

  useEffect(() => {
  }, [completedSteps]);

  const handleAddQualification = () => {
    let newQualification = {
      name: "adsa",
      qualification: "",
      value: "",
      range: {
        from: 0,
        to: 0,
      },
    };
    let qualificationDuplicate = [...qualification];
    qualificationDuplicate.push(newQualification);
    setQualification(qualificationDuplicate);
  };

  const handleChangeQualificationData = (e, index) => {
    const { value, name } = e.target;
    let duplicate = [...qualification];
    if (name === "from" || name === "to") {
      duplicate[index]["range"][name] = value;
    } else {
      duplicate[index][name] = value;
    }
    setQualification(duplicate);
  };
  return (
    <>
      <TopHeading
        link={"/admin/survey"}
        selectedSurveyStep={selectedStep}
        // handleAddQualification={handleAddQualification}
        heading={"Survey"}
        showButton={Boolean(false)}
      />
      <Box sx={{ ml: 4, mt: 4 }}>
        <SurveySteps
          handleChange={handleChangeSelected}
          selectedStep={selectedStep}
          steps={surveySteps}
          completedSteps={completedSteps}
        />
        {selectedStep === 0 &&
          (fetchingdata ? (
            <CircularProgress className="loader" />
          ) : (
            <SurveyForm
              completedSteps={completedSteps}
              handleChangeSelected={handleChangeSelected}
              handleCompleteStep={handleCompleteStep}
              selectedStep={selectedStep}
              setAlert={setAlert}
              setSurveyResdata={setSurveyResdata}
              editData={editData}
              type={surveyState?.type === "EDIT" ? "EDIT" : "ADD"}
            />
          ))}
        {selectedStep === 1 && (
          <Qualification
            alert={alert}
            setAlert={setAlert}
            handleChange={handleChangeQualificationData}
            surveyResdata={surveyResdata}
          />
        )}
 {selectedStep === 3 && (
          <SurveyEmail 
            alert={alert}
            setAlert={setAlert}
            handleChange={handleChangeQualificationData}
            surveyResdata={surveyResdata}
          />
        )}

      </Box>
      <SnackBarAlert open={alert} handleClose={handleCloseAlert} />
    </>
  );
};

export default CreateSurvey;
