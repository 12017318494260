import React, { useState, useEffect } from "react";
import { Edit, Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import Confcampaign from "./confCampaign";
import TopHeading from "../../utils/TopHeading/TopHeading";
import { getActivepanels, getAllCampaign, deleteCampaign } from "./campaign.api";
import Hoc from "../HOC/Hoc";
import CustomTable from "../../views/CustomTable/CustomTable";
import AnimatedPage from "../animatedPage/AnimatedPage";
import moment from "moment/moment";

const CampaignPage = (props) => {
  const {
    data,
    loading,
    page,
    setPage,
    pageCount,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setSuccess,
    setError,
    openEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    setAlert,
    alert,
    closeAlert,
    showPagination,
    setShowPagination,
    hidePagination,
  } = props;
  const handleModalClose = () => {
    handleCloseModal();  
    setDataUpdated((prev) => !prev);
    // This will close the modal in the parent component
  };
  const handleModalEditClose = () => {
    handleCloseEditModal();  
    setDataUpdated((prev) => !prev);
    // This will close the modal in the parent component
  };
  const [activePanels, setActivePanels] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState({});
  const [pageloading, setPageloading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedPanelId, setselectedPanelId] = useState();
  const [pending, setPending] = useState(true);
     const [editId, seteditId] = useState({});
  //data
  const [rows, setRows] = useState([]);
  const labels = ["Campaign Name", "URL", "Points", "Launch Date", "Actions"];
  const adminId = useSelector((state) => state.auth.userData?.adminId);

  //functions
  const handleDropDownChange = (value, dropDownName) => {
    switch (dropDownName) {
      case "PANEL":
        setSelectedPanel(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getActivepanels(adminId)
      .then((res) => {
        if (res?.status === 200) {
          const { data } = res.data;
          setActivePanels(data);
          setSelectedPanel(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setActivePanels([]);
      setSelectedPanel({});
      setDataUpdated(false);
    };
  }, [adminId]);

  useEffect(() => {
    setPageloading(true);
    getAllCampaign()
      .then((res) => {
        setPageloading(false);
        const getAllCampaigns = res.data.data;
        const updatedRows = getAllCampaigns.map((item) => [
          item.campaignName,
          item.campaignUrl,
          item.points,
          moment(item.launchDate).format("YY-MM-DD"),
          <div style={{ display: "flex", gap: "10px" }}>
            {/* Edit Icon with Click Event */}
            <Edit
              style={{ cursor: "pointer" }}
              onClick={() => handleEditClick(item)}
            />
            {/* Delete Icon with Click Event */}
            <Delete
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(item)}
            />
          </div>,
        ]);
        setRows(updatedRows);
      })
      .catch((err) => {
        setPageloading(false);
      });
    // setAllCampaigns
    return () => {
      setRows([]);
      setPending(false);
    };
  }, [dataUpdated]);

  const handleEditClick = (item) => {
    handleOpenEditModal();
    setSelectedItem(item);
    seteditId(item.campaignId);
  };
  const handleDelete = (item) => {
    deleteCampaign(item.campaignId).then((res) => {
      if (res?.status === 200) {
        setSuccess("Campaign Deleted Successfully");
        setDataUpdated(prev => !prev);
      }
    });
  };
  
  
  return (
    <>
      <AnimatedPage>
        <TopHeading heading="campaign" openModal={handleOpenModal} />
        <Stack sx={{ mx: 4, my: 2 }}>
          <AsyncDropdown
            handleChange={(value) => handleDropDownChange(value, "PANEL")}
            value={selectedPanel}
            options={activePanels}
            objKey={"panelName"}
            stylized
            label={"Select Panel"}
          />
        </Stack>
        <Confcampaign
          open={openModal}
          loading={loading}
          type="ADD"
          hadnleCloseModal={handleModalClose}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          activePanels={activePanels}
          setDataUpdated={setDataUpdated}
          dataUpdated={dataUpdated}
        />
        <Confcampaign
          open={openEditModal}
          loading={loading}
          type="EDIT"
          hadnleCloseModal={handleModalEditClose}
          setSuccess={setSuccess}
          setError={setError}
          setAlert={setAlert}
          editId={editId}
          selectedPanelId={selectedPanelId}
          selectedItem={selectedItem}
          activePanels={activePanels}
          setDataUpdated={setDataUpdated}
          dataUpdated={dataUpdated}
        />
        <CustomTable
          pagination={true}
          progressPending={pending}
          highlightOnHover
          labels={labels}
          data={rows}
          pageLoading={pageloading}
        />
      </AnimatedPage>
    </>
  );
};

export const Campaign = Hoc(CampaignPage, { name: "" });
