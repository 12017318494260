import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Copy icon
import CheckIcon from "@mui/icons-material/Check"; 
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black, // Arrow color
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#333",          // Tooltip background color
    color: "#fff",                    // Tooltip text color
    padding: "8px 10px", 
    fontSize: "12px",             // Padding inside the tooltip
    borderRadius: "6px",
    maxWidth: "300px",    
    whiteSpace: "normal",
    wordBreak: "break-word",
    display: "flex",
    alignItems: "center",
    gap: "5px"          // Rounded corners
  },
}));

export default function CustomTooltip({ title, childrenText, copiedText = false, placement = "top" }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (copiedText && title) {
      navigator.clipboard.writeText(title);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset icon after 2 seconds
    }
  };
  return (
    <BootstrapTooltip 
    title={
      <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {title}
        {copiedText && (copied ? <CheckIcon style={{ fontSize: 14, color: "green" }} /> : <ContentCopyIcon style={{ fontSize: 14, cursor:"pointer" }} onClick={handleCopy} />)}
      </span>
    } 
    placement={placement}
  >
    <span 
      style={{
        cursor: copiedText ? "pointer" : "default",
        display: "inline-block",
        maxWidth: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {childrenText}
    </span>
  </BootstrapTooltip>
  );
}
