import axios from "axios";

export const getActivepanels = async (id) => {
    try {
      const res = await axios.get(
        `/api/Company/getActiveTenantPanel?tenantId=${id}`
      );
      return res;
    } catch (error) {
      console.log(error.message);
    }
  };



export const createCampaign = async (data) => {
  try {
    const res = await axios.post("/api/Company/createCampaign", data);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};


export const getAllCampaign = async () => {
  try {
    const res = await axios.get(`/api/Company/getAllCampaign`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};




export const deleteCampaign = async (id) => {
  try {
    const res = await axios.delete(`/api/Company/deleteCampaign?campaignId=${id}`);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};



export const updateCampaign = async (id, data) => {
  try {
    const res = await axios.patch(`/api/Company/updateCampaign?campaignId=${id}`, data);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};