import logo from "./logo.svg";
import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import HomeLayout from "./layout/homeLayout/HomeLayout";
import Dashboard from "./components/dashboard/Dashboard";
import Home from "./components/Home/Home";
import Data from "./components/Data/Data";
import { backendUrl } from "./config/keys";
import axios from "axios";
import Default from "./components/Default";
import AuthRoutes from "./lib/AuthRoutes";
import Login from "./components/auth/Login/Login";
import PrivateRoutes from "./lib/PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "./features/authSlice";
import { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import AuthLayout from "./layout/AuthLayout/AuthLayout";
import InProgress from "./components/InProgress";
import Profile from "./components/Profile/Profile";
import { EnhancedUsers } from "./components/users/Users";
import Register from "./components/auth/Register/Register";
import { EnhancedCountry } from "./components/country/Country";
import { EnhancedRoles } from "./components/roles/Roles";
import { EnhancedLanguage } from "./components/language/Language";
import { EnhancedFeature } from "./components/feature/Feature";
import { EnhancedContent } from "./components/content/Content";
import { EnhancedCategory } from "./components/category/Category";
import { EnhancedAnswerType } from "./components/answerType/AnswerType";
import { EnhancedPanel } from "./components/panel/Panel";
import { EnhancedMasterQuestion } from "./components/masterQuestions/MasterQuestion";
import { EnhancedCompanyBoard } from "./components/companyOnBoard/CompanyOnBoard";
import { EnhancedManageMember } from "./components/manageMembers/ManageMembers";
import { EnhancedTenantProfile } from "./components/tenantProfile/TenantProfile";
import { EnhancedPanelAdmin } from "./components/managePanel/ManagePanel";
import { EnhancedCompanyFeatures } from "./components/companyFeatures/CompanyFeatures";
import { AnimatePresence } from "framer-motion";
import { EnhancedPanelProfile } from "./components/panelProfile/PanelProfile";
import { EnhancedAssignedSurveyCountries } from "./components/assignedSurveyCountries/AssignedSurveyCountries";
import { EnhancedAssignedFeatures } from "./components/assignedFeatures/AssignedFeatures";
import { EnhancedAssociatedPanelMembers } from "./components/associatedPanelMembers/AssociatedPanelMembers";
import AssignedPanelPage from "./components/assignedPanelPage/AssignedPanelPage";
import { EnhancedQuestionTranslations } from "./components/masterQuestions/questionTranslationsPage/QuestionTranslationsPage";
import { EnhancedCategoryTranslationsPage } from "./components/category/categoryTranslations/categoryTranslationsPage";
import { EnhancedUserProfileQuestionaire } from "./components/userProdileQuestionaire/UserPeofileQuestionaire";
import { EnhancedSurvey } from "./components/survey/Survey";
import Create from "./components/companyOnBoard/Create";
import TenantList from "./components/companyOnBoard/tenantList";
import TenantPanels from "./components/companyOnBoard/tenantPanels/TenantPanels";
import SinglePanel from "./components/companyOnBoard/tenantPanels/SinglePanel";
import AddProfileQuestionaire from "./components/userProdileQuestionaire/addProfileQuestionaire/AddProfileQuestionaire";
import ViewUserProfileQuestionaire from "./components/userProdileQuestionaire/viewUserProfileQuestionaire/ViewUserProfileQuestionaire";
import AssignQuestions from "./components/assignQuestions/AssignQuestions";
import TranslateQuestions from "./components/translateQuestions/TranslateQuestions";
import CreateSurvey from "./components/survey/createSurvey/CreateSurvey";
import { createBrowserHistory } from "history";
import SuperAdminRoutes from "./lib/SuperAdminRoutes";
import RoleRoutes from "./lib/RoleRoutes";
import VerifySurvey from "./components/verifySurvey/VerifySurvey";
import EditUserProfileQuestionaire from "./components/userProdileQuestionaire/editUserProfileQuestionaire/EditUserProfileQuestionaire";
import VerifyReturnStatus from "./components/verifyReturnStatus/VerifyReturnStatus";
import Test from "./components/Test";
import { EnhancedMiniSurvey } from "./components/miniSurvey/MiniSurvey";
import { Campaign } from "./components/campaign/campaign";
import { EnhancedRedemption } from "./components/RedemptionRequest/Redemption";
import { EnhancedTangoCard } from "./components/TangoCard/TangoCard";
import VerifiedUser from "./components/VerifiedUser";
import EmailVerification from "./components/emailVerification/EmailVerification";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import { store } from "./store/store";
import { EnhancedPanelistsPage } from "./components/panelistDetail/panelistDetail";
import EditPanelist  from "./components/panelistDetail/editPanelist/EditPanelist";
import { EnhancedAffliateReport } from "./components/affiliateReport/affiliateReport";
import { EnhancedAffliateMaster } from "./components/affiliateMaster/affiliateMaster";
import CreateAffiliate from "./components/affiliateMaster/createAffiliate/CreateAffiliate"
axios.interceptors.request.use(async (request) => {
  request.url = backendUrl + request.url;
  request.headers = {
    ...request.headers,
  };
  const token = JSON.parse(localStorage.getItem("uni-panel-myuser"));
  if (token?.token?.access?.token) {
    let accessToken = token?.token?.access?.token;
    request.headers.Authorization = "Bearer " + accessToken;
  }
  return request;
});

axios.interceptors.response.use(
  function (response) {
    if (response?.status >= 200 && response?.status <= 299) {
      return response;
    }
  },
  function (error) {
    if (error?.response?.data?.message === "invalid token") {
      store.dispatch(logout());
    }
    return error;
  }
);

function App() {
  const dispatch = useDispatch();
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const tenantId = useSelector((state) => state.auth.userData?.tenantId);
  const roleId = useSelector((state) => state.auth.userData?.roleId);

  const user = JSON.parse(window.localStorage.getItem("uni-panel-myuser"));
  if (user) {
    dispatch(login(user));
  }

  const hist = createBrowserHistory();

  function ValidateUser() {
    let { id } = useParams();
    let userId = id.match(/\^[0-9]*[1-9][0-9]*$/);
    if (userId) {
      return <VerifyReturnStatus />;
    }
    return <EnhancedTenantProfile />;
  }

  const location = useLocation();
  return (
    <>
      <AnimatePresence initial={false}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/admin" element={<HomeLayout />}>
              {/* super admin */}
              <Route element={<RoleRoutes allowedRoles={[1]} />}>
                <Route path="users" element={<EnhancedUsers />} />
              </Route>
              {/* super admin end */}

              {/* system and super admin */}
              <Route element={<RoleRoutes allowedRoles={[1, 2]} />}>
                <Route
                  path="company-on-board"
                  element={<EnhancedCompanyBoard />}
                ></Route>
                <Route path="country" element={<EnhancedCountry />} />
                <Route path="feature" element={<EnhancedFeature />} />
              </Route>
              {/* system and super admin  end*/}

              {/* admin and tenant */}
              <Route element={<RoleRoutes allowedRoles={[1, 2, 3]} />}>
                <Route
                  path="master-questions"
                  element={
                    <EnhancedMasterQuestion
                      userId={roleId === 3 ? adminId : undefined}
                    />
                  }
                />
                <Route
                  path="category"
                  element={<EnhancedCategory roleId={roleId} />}
                />
                <Route path="tenant-panels/:id" element={<TenantPanels />} />
                <Route
                  path="tenant-panels/panel/:id"
                  element={<SinglePanel />}
                />
              </Route>
              {/* admin and tenant end */}

              {/* only tenant */}
              <Route element={<RoleRoutes allowedRoles={[3]} />}>
                <Route
                  path="tenant-profile"
                  element={<EnhancedTenantProfile />}
                />
                <Route
                  path="manage-members"
                  element={<EnhancedManageMember userId={adminId} />}
                />
                <Route
                  path="panel-admin"
                  element={<EnhancedPanelAdmin userId={adminId} />}
                />
                <Route
                  path="roles"
                  element={<EnhancedRoles userId={adminId} />}
                />
                <Route
                  path="category"
                  element={<EnhancedCategory roleId={roleId} />}
                />
                <Route path="assign-questions" element={<AssignQuestions />} />
                <Route
                  path="panel-questions"
                  element={<TranslateQuestions />}
                />
                <Route path="panel" element={<EnhancedPanel />} />
                <Route
                  path="/admin/user-profile-questionaire"
                  element={<EnhancedUserProfileQuestionaire userId={adminId} />}
                />
                <Route
                  path="/admin/user-profile-questionaire/:id"
                  element={<ViewUserProfileQuestionaire />}
                />
                <Route
                  path="/admin/user-profile-questionaire/:id/edit"
                  element={<EditUserProfileQuestionaire />}
                />
                <Route
                  path="/admin/user-profile-questionaire/create"
                  element={<AddProfileQuestionaire />}
                />
                <Route path="mini-survey" element={<EnhancedMiniSurvey />} />
                <Route path="campaign" element={<Campaign />} />
                <Route
                  path="panelist-redemption-request"
                  element={<EnhancedRedemption />}
                />
                <Route path="tango-card" element={<EnhancedTangoCard />} />
                <Route path="/admin/create-survey" element={<CreateSurvey />} />
                <Route
                  path="/admin/edit-survey/:id"
                  element={<CreateSurvey />}
                />
                <Route
                  path="associated-panel-members"
                  element={
                    <EnhancedAssociatedPanelMembers
                      userId={adminId}
                      tenantId={tenantId}
                    />
                  }
                />
                <Route path="/admin/survey" element={<EnhancedSurvey />} />

                <Route
                  path="panel-questions/translated/:id"
                  element={<EnhancedQuestionTranslations />}
                />
                <Route
                  path="categiry-translations/:id"
                  element={<EnhancedCategoryTranslationsPage />}
                />
                <Route
                  path="/admin/panelists"
                  element={<EnhancedPanelistsPage />}
                />
                <Route
                  path="/admin/panelist-detail/:id"
                  element={<EditPanelist />}
                />
                <Route
                  path="/admin/affiliate-report"
                  element={<EnhancedAffliateReport />}
                />
                <Route
                path="/admin/affiliate-master"
                element={<EnhancedAffliateMaster />}
                />
                <Route path="/admin/create-affiliate" element={<CreateAffiliate />} />
                <Route
                  path="/admin/edit-affiliate/:id"
                  element={<CreateAffiliate />}
                />
                <Route
                  path="master-questions"
                  element={
                    <EnhancedMasterQuestion
                      userId={roleId === 3 ? adminId : undefined}
                    />
                  }
                />
              </Route>
              {/* tenant end */}

              {/* <Route
                path="tenant-features"
                element={<EnhancedCompanyFeatures userId={adminId} />}
              />

              <Route path="panel/:id" element={<AssignedPanelPage />} />

              <Route
                path="assigned-survey-countries"
                element={<EnhancedAssignedSurveyCountries userId={adminId} />}
              />
              <Route
                path="assigned-features"
                element={<EnhancedAssignedFeatures userId={adminId} />}
              /> */}

              <Route path="*" element={<Default />} />
            </Route>
          </Route>
        </Routes>
        <Routes key={location.pathname} location={location}>
          {/* <Route path="/test" element={<Test />} /> */}
          <Route path="/complete-status" element={<VerifySurvey />} />
          <Route path="/email-verify" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/:id/:token" element={<VerifiedUser />} />
          <Route element={<AuthRoutes />}>
            <Route path="/" element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>
          </Route>
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
